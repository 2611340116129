export function Experience() 
{
    return (
      <>
        <h2>Work Experience:</h2>
        <Job company="Rice Lake Weighing Systems" type="Full-Time" role="Software Engineer" startdate="July 8th, 2024" enddate="Present" 
        desc="Worked part of the embedded development team on product hardware. Used C, C++, git, GitHub and Linux. Also tested software with catch and ceedling." 
        company_link="https://www.ricelake.com/" position_type="onsite"/>
        <Job company="Xorbix Technologies" type="Intern" role="Software Developer" startdate="June 1st, 2023" enddate="January 12, 2024" 
        desc="Worked alongside other developers to create Fullstack software applications for company clients. Used git, SQL, C# Dot Net, and JavaScript with React." 
        company_link="https://xorbix.com/" position_type="remote"/>

        <h2>Education:</h2>
        <Education inst_name="Milwaukee School of Engineering (MSOE)" startdate="" enddate="May 11th, 2024" study="Majored in Computer Engineering" honors={true}/>
      </>
    );
}

function Job({company, role, type, desc, startdate, enddate, company_link, position_type, ...props})
{
    return (
        <div className="job">
            <p>{type} {role} at {company}</p>
            <p>Started: {startdate} to {enddate}, {position_type} position</p>
            <a href={company_link}>Company Site</a>
            <p>{desc}</p>
        </div>
    );
}

function Education({inst_name, startdate, enddate, study, honors, ...props})
{
    return (
        <div className="job">
            <p>{inst_name}</p>
            <p>Graduated {enddate} {honors ? "with Honors": ""}</p>
            <p>{study}</p>
            <p>Relevant topics:</p>
            <ul>
                <li>Programming</li>
                <li>Embedded Development</li>
                <li>Digital logic, FPGA, VHDL</li>
                <li>DC/AC circuits</li>
                <li>Calculus, Statistics, Discrete logic</li>
                <li>And more.</li>
            </ul>
        </div>
    );
}
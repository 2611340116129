export function Home() {
    return (
      <>
      <div className="home-card">
        <h1>Welcome to my website!</h1>
      </div>
      <div className="home-card">
        <h1>Sections:</h1>
        <ul className="home-list">
          <li><b>Home</b> &emsp; - &emsp; This page</li>
          <li><b>About</b> &emsp; - &emsp; More about me.</li>
          <li><b>Contact</b> &emsp; - &emsp; How to get in touch.</li>
          <li><b>Documents</b> &emsp; - &emsp; Resume, transcripts, etc.</li>
          <li><b>Experience</b> &emsp;&emsp;
          - &emsp; Work, project, and education info.</li>
          <li><b>Projects</b> &emsp;&emsp;&emsp; - &emsp; Showcase of personal work.</li>
        </ul>
      </div>
      </>
    );
  }
export function Projects() {
    return (
      <>
        <Project name="Temperature Simulation" date="8/1/24" desc="Temperature conduction simulator in C++ with custom graphics renderer."
         pics={null} gitLink={"https://github.com/MikeRPar/TemperatureSimulation"} imgFile="temperatureproj.png" videoLink="https://youtu.be/fJNK9aSFmKU"/>
        <Project name="Professional Site" date="7/28/24" desc="Simple landing page with information about myself, projects, and contacts!" 
        pics={null}/>
      </>
    );
  }

function Project({name, date, desc, videoLink, gitLink, imgFile, ...props})
{
  return(
    <>
      <div className="project-container">
        <h2>{name}</h2>
        <p>{date}</p>
        <p>{desc}</p>
        <p>Video Link: <a href={videoLink}>{name} Demo</a></p>
        <p>{gitLink ? <a href={gitLink}>GitHub Repo</a> : ""}</p>
        {imgFile ? <img className="project-image" src={imgFile} width="200px" height="200px"/>: ""}
      </div>
    </>
  );
}
import { Link, useResolvedPath, useMatch } from "react-router-dom"


export function Navbar() {
    return (
    <nav className="navbar">
        <ul>
          <CustomLink to ="/">Home</CustomLink>
          <CustomLink to ="/projects">Projects</CustomLink>
          <CustomLink to ="/about">About</CustomLink>
          <CustomLink to ="/contact">Contact</CustomLink>
          <CustomLink to ="/experience">Experience</CustomLink>
          <CustomLink to ="/documents">Documents</CustomLink>
        </ul>
      </nav>
      );
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to);
    //needs path to fully match, reconsider if adding querying from URL in future
    const isActive = useMatch({path: resolvedPath.pathname, end: true});
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}
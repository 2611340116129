import {  Route, Routes } from "react-router-dom"
import { Home } from "./pages/Home"
import { About } from "./pages/About"
import { Documents } from "./pages/Documents"
import { Experience } from "./pages/Experience"
import { Projects } from "./pages/Projects"
import { Contact } from "./pages/Contact"
import { Navbar } from "./Navbar"
import './App.css';

function App() {
  return (
    <>
    <Navbar />

    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/projects" element={<Projects />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/documents" element={<Documents />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/experience" element={<Experience />}></Route>
    </Routes>
    </>
  );
}

export default App;

export function Contact() {
    return (
      <>
        <ContactCard name="Email" isEmail={true} imgFile="/email.png" link="michaelrparlacoski@gmail.com" desc="Reach out via email!"/>
        <ContactCard name="LinkedIn" isEmail={false} imgFile="/LinkedIn.png" link="https://www.linkedin.com/in/michael-parlacoski/" desc="Send a message or view my posts on LinkedIn!"/>
      </>
    );
  }

function ContactCard({name, isEmail, imgFile, link, desc, ...props})
{
  return (
    <>
    <div className="contact-container">
      <img src={imgFile} width="100px"  height="100px"/>
      <div className="contact-info-container">
        <h3 className="contact-name">{name}</h3>
        <p className="contact-desc"> {desc} {isEmail ? "(Best way for contact, business only do not send spam or sales offers)" : ""}
          {isEmail ? <><br /> Email Address: {link}</> : <><br /><a href={link}>{name} Page</a></>}
        </p>
      </div>
    </div>
    </>
  );
}
export function About() {
    return (
      <>
      <div className="about-container">
        <h2>About me:</h2>
        <p>Hi, I'm Michael, a computer engineer currently working at Rice Lake Weighing Systems as part of the embedded development team. <br />
        I recently graduated from Milwaukee School of Engineering for my Bachelor's in Computer Engineering. </p>
      </div>

      <div className="me-container">
        <img src="Me.JPG" width="150px" height="150px" id="me"/>
      </div>

      <div className="interest-container">
        <h2>My interests:</h2>
        <ul>
          <li>Game Development</li>
          <li>Blender 3D Modeling</li>
          <li>Guitar</li>
          <li>3D Printing</li>
          <li>PCB and hardware design</li>
        </ul>
      </div>

      <div className="skill-container">
        <h2>My skills:</h2>
        <ul>
          <li>Programming:</li>
            <ul>
              <li>ARM assembly</li>
              <li>C, C++</li>
              <li>Java</li>
              <li>Python</li>
              <li>HTML, CSS, and JavaScript</li>
              <li>SQL</li>
            </ul>

          <li>Microsoft office:</li>
            <ul>
              <li>Microsoft Docs</li>
              <li>Power Point</li>
              <li>Excel</li>
            </ul>

          <li>Hardware Design:</li>
            <ul>
              <li>DC/AC circuits</li>
              <li>PCB Design (KiCad)</li>
              <li>FPGA with VHDL</li>
              <li>Circuit Simulation and testing</li>
              <li>Embedded Development</li>
            </ul>

          <li>Other skills:</li>
            <ul>
              <li>Linux, Ubuntu, WSL, Kali, Linux Mint</li>
              <li>Git, GitHub, GitLab</li>
              <li>Microcontrollers, STM32, ESP32, Arduino</li>
            </ul>
        </ul>
      </div>
      </>
    );
  }
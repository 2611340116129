export function Documents() {
    return (
      <>
      <div className="doc-card">
        <h2>Resume:</h2>
        <a href="Parlacoski Resume 2024.pdf" download >Resume download</a>
      </div>

      <div className="doc-card">
        <h2>Transcripts:</h2>
        <p>To receive a copy, please <a href="/contact">contact</a> me to make a request.</p>
      </div>
      </>
    );
  }